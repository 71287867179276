<template>
  <BaseDeleteModal :text='t("AlertDeleteTypeFilament")' :isVisible="typeFilamentStore.showModalDelete"
     @closeModal="closeModalDelete" />

  <CreateTypeFilamentForm />

  <div class="flex flex-wrap mx-8 mb-8">
    <IdCardTypeDeFilament v-for="type in typeFilamentStore.typeFilaments" :key="type.uuid"
          :typeFilament="type" :show-modal-delete="showModalDelete" />
  </div>

  <TitreUn v-if="typeFilamentStore.typeFilaments.length === 0" :content='t("noTypeFilament")'
       class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]"/>
</template>

<script setup>

import {ref} from "vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "@/utils/Traduction";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import IdCardTypeDeFilament from "@/components/organismes/IdCardTypeDeFilament.vue";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";
import CreateTypeFilamentForm from "@/components/organismes/CreateTypeFilamentForm.vue";
import TitreUn from "@/components/atoms/TitreUn.vue";
import BaseDeleteModal from "@/components/molecules/BaseDeleteModal.vue";

const typeFilamentStore = TypeFilamentStore();
const notificationStores = NotificationStores();
const utilisateurStore = UtilisateurStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStores.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/" });
}


typeFilamentStore.loadTypeFilaments();

const uuidTypeFilamentModale = ref(0);

const closeModalDelete = (supprimer) => {
  if(supprimer) {
    typeFilamentStore.deleteTypeFilament(uuidTypeFilamentModale.value);
  }

  typeFilamentStore.showModalDelete = false;
}

const showModalDelete = (uuid) => {
  uuidTypeFilamentModale.value = uuid;
  typeFilamentStore.showModalDelete = true;
}

</script>

<style scoped>

</style>