<template>
  <button class="text-white" :class="{'cursor-pointer': $attrs.onClick}">{{ props.text }}</button>
</template>

<script setup>

import {defineProps} from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true
  }
})
</script>


<style scoped>

</style>