<template>
  <div ref="container" class="h-[70vh]"></div>
</template>

<script setup>

import {ProduitStores} from "@/stores/ProduitStore";
import {defineExpose, defineProps, nextTick, onMounted, useTemplateRef, watch} from "vue";
import * as THREE from "three";
import {OrbitControls} from "three/addons/controls/OrbitControls";
import {STLLoader} from "three/addons/loaders/STLLoader";

const produitStore = ProduitStores();
const containerRef = useTemplateRef("container");
let scene = null;
let renderer = null;
let mesh = null;
let controls = null;
let camera = null;
let isAnimating = true; // Drapeau pour contrôler l'animation


const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  uuidProduit: {
    type: String,
    required: false,
  },
  base64Data: {
    type: String,
    required: false,
  },
  hideModal: {
    type: Function,
    required: true,
  },
});

onMounted(() => {
  initScene();

  watch(() => props.uuidProduit, () => {
    clearScene();
    initScene();

    document.addEventListener('keydown', (event) => {
      if(event.key === 'Escape') {
        closeModal();
      }
    });
  });

  watch(() => props.base64Data, () => {
    clearScene();
    nextTick(() => {
      initScene();
    });

    document.addEventListener('keydown', (event) => {
      if(event.key === 'Escape') {
        closeModal();
      }
    });
  });
});

watch(() => props.isVisible, (newValue) => {
  if (newValue) {
    // Réinitialiser la scène lorsqu'on ouvre la modal
    clearScene();
    nextTick(() => {
      initScene();
    });
  }
});

const clearScene = () => {
  isAnimating = false;
  if (scene) {
    if (mesh) {
      mesh.geometry.dispose();
      if (mesh.material instanceof THREE.Material) {
        mesh.material.dispose();
      } else {
        mesh.material.forEach((material) => material.dispose());
      }
      scene.remove(mesh);
    }

    renderer.dispose();
    renderer.forceContextLoss();
    renderer.domElement = null;
    renderer.content = null;
    scene = null;
    mesh = null;
  }

  if(containerRef.value) {
    while (containerRef.value.firstChild) {
      containerRef.value.removeChild(containerRef.value.firstChild);
    }
  }

};

const closeModal = () => {
  isAnimating = false; // Arrêter l'animation avant de fermer la modal
  props.hideModal();
};

const render = (base64) => {
  // Création de la scène
  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x202020); // Couleur de fond de la scène

  camera = new THREE.PerspectiveCamera(75, containerRef.value.clientWidth / containerRef.value.clientHeight, 0.1, 1000);

  renderer = new THREE.WebGLRenderer({antialias: true});
  renderer.setSize(containerRef.value.clientWidth, containerRef.value.clientHeight);
  renderer.shadowMap.enabled = true; // Activer les ombrages
  containerRef.value.appendChild(renderer.domElement);

  // Initialisation des contrôles
  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;

  // Lumière ambiante pour éclairage général
  const ambientLight = new THREE.AmbientLight(0x404040, 0.7); // Lumière ambiante plus forte
  scene.add(ambientLight);

  // Lumières directionnelles pour un éclairage ciblé
  const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.6);
  directionalLight1.position.set(5, 10, 7.5);
  directionalLight1.castShadow = true;
  scene.add(directionalLight1);

  const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.6);
  directionalLight2.position.set(-5, -10, -7.5);
  scene.add(directionalLight2);

  // Lumières ponctuelles pour illuminer l'objet de tous les côtés
  const pointLight1 = new THREE.PointLight(0xffffff, 0.8);
  pointLight1.position.set(0, 10, 0);
  scene.add(pointLight1);

  const pointLight2 = new THREE.PointLight(0xffffff, 0.8);
  pointLight2.position.set(10, 0, 10);
  scene.add(pointLight2);

  const pointLight3 = new THREE.PointLight(0xffffff, 0.8);
  pointLight3.position.set(-10, 0, -10);
  scene.add(pointLight3);

  const pointLight4 = new THREE.PointLight(0xffffff, 0.8);
  pointLight4.position.set(0, -10, 0);
  scene.add(pointLight4);

  try {
    const binaryData = atob(base64);
    const arrayBuffer = new Uint8Array(binaryData.length);

    for(let i = 0; i < binaryData.length; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }

    const loader = new STLLoader();
    const geometry = loader.parse(arrayBuffer.buffer);
    const material = new THREE.MeshPhongMaterial({color: 0x3498db, shininess: 100}); // Couleur moderne avec réflexion
    mesh = new THREE.Mesh(geometry, material);
    mesh.castShadow = true;  // L'objet projette des ombres
    mesh.receiveShadow = true;  // L'objet reçoit des ombres

    scene.add(mesh);

    // Calcul de la boîte englobante de l'objet
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const size = boundingBox.getSize(new THREE.Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = Math.abs(maxDim / (2 * Math.tan(fov / 2)));

    cameraZ *= 1.2; // Ajout d'un petit facteur pour éloigner un peu la caméra

    // Recentrer l'objet dans la scène et positionner la caméra
    const center = boundingBox.getCenter(new THREE.Vector3());
    camera.position.set(center.x + maxDim, center.y + maxDim / 2, cameraZ);
    controls.target.set(center.x, center.y, center.z);
    controls.update();

    const animate = () => {
      if(!isAnimating) return; // Sortir de l'animation si isAnimating est false
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    isAnimating = true;
    animate();
  } catch(error) {
    console.error('Erreur lors du décodage base64 :', error);
  }
}

const initScene = () => {
  if(scene) {
    clearScene();
  }

  if(props.uuidProduit === "" && props.base64Data === "") {
    return;
  }

  if(props.base64Data && props.base64Data !== '') {
    render(props.base64Data.split(",")[1]);
  } else if(props.uuidProduit) {
    produitStore.getStl(props.uuidProduit, (response) => {

      // Chargement du fichier STL
      let base64Data = response.response.split(",")[1];

      render(base64Data);
    });
  }
};

defineExpose({closeModal})

</script>

<style scoped>

</style>