<template>
  <div class="flex flex-col items-start">
    <TitreDeux :content='t("statsPerColor")' />
    <BlueClickableText :text='t("addColor")' @click="createCouleur" />

    <BaseKeyValue v-for="(objet, couleur) in statistiquesParCouleur.value" :key="couleur.uuid" :cles="objet.libelle">
      <SimpleText :content="objet.poidsRestant + 'g'"  :cles="objet.libelle" />
    </BaseKeyValue>
  </div>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import {reactive, watch} from "vue";
import {BobineStore} from "@/stores/BobineStore";
import {CouleurStore} from "@/stores/CouleurStore";
import BlueClickableText from "@/components/molecules/BlueClickableText.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";

const statistiquesParCouleur = reactive({});
const bobineStore = BobineStore();
const couleurStore = CouleurStore();

watch(() => bobineStore.bobines, () => {
  const stats = {};
  bobineStore.bobines.forEach(bobine => {
    if (!stats[bobine.couleur.uuid]) {
      stats[bobine.couleur.uuid] = {poidsRestant: 0, libelle: bobine.couleur.libelle};
    }
    stats[bobine.couleur.uuid].poidsRestant += bobine.poidsRestant;
  });

  statistiquesParCouleur.value = stats;
}, { deep: true, immediate: true });

const createCouleur = () => {
  couleurStore.showModalCreation = true;
}
</script>


<style scoped>

</style>