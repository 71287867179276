<template>
  <ModalCreationFailedObjectComponent :hideModal="hideCreateFailedModal" :produit="produitModalFailed" :isVisible="isModalCreateFailedVisible !== ''" />
  <Modal3DRender :base64-data="base64ProduitToRender3D" :uuidProduit="uuidProduitToRender3D" :hideModal="hideModal3DRender" :isVisible="show3DRenderModal" />

  <div class="bg-gray-100 p-4 rounded-lg shadow-md">
    <div class="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
      <TitreDeux :content="commandeStores.commandeSelected.nom"/>

      <div class="flex flex-col md:flex-row md:items-center gap-2">
        <EtatChips :etat="commandeStores.commandeSelected.etat" />

        <BlueButton :text="t('changeToStatus') + ' ' + enumeration.getTraductionByEtat(enumeration.etatCommande.TERMINEE_ET_PAYEE)"
          v-show="isAdminEtTerminer"
          @click="setToStatusPayed"/>

        <BlueButton :text="t('changeToStatus') + ' ' + enumeration.getTraductionByEtat(enumeration.etatCommande.ANNULEE)"
            v-show="isAdminEtEnCourOrAFaireAvecAucunProduitAFaire"
          @click="setToStatusCancel"/>

        <div class="absolute md:relative top-20 md:top-0 right-4 md:right-0">
          <EuroIcon v-show="!commandeStores.commandeSelected.estGratuite"
                @click="isAdmin ? toogleEstGratuit() : undefined" class="text-xl" />
          <EuroBarrerIcon v-show="commandeStores.commandeSelected.estGratuite"
                @click="isAdmin ? toogleEstGratuit() : undefined" class="text-xl" />
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row justify-between items-start md:items-end">
      <div class="flex flex-col">
        <BaseKeyValue :cles="t('uuid')">
          <SimpleText :content="commandeStores.commandeSelected.uuid" />
        </BaseKeyValue>

        <BaseKeyValue :cles="t('startDate')">
          <SimpleText :content="dateFormater" />
        </BaseKeyValue>

        <BaseKeyValue :cles="t('user')" v-if="isAdmin"
              @click="openUserStats(commandeStores.commandeSelected.utilisateur.uuid)">
          <SimpleText :content="commandeStores.commandeSelected.utilisateur.email" />
        </BaseKeyValue>

        <BaseKeyValue :cles="t('user')" v-else>
          <SimpleText :content="commandeStores.commandeSelected.utilisateur.email" />
        </BaseKeyValue>

        <BaseKeyValue :cles="t('delivry')">
          <SimpleText :content="commandeStores.commandeSelected.utilisateur.livraison" />
        </BaseKeyValue>
      </div>

      <div class="flex flex-col mt-4 md:mt-0" v-show="!commandeStores.commandeSelected.estGratuite">
        <div class="flex flex-row items-center gap-4">
          <VaryingIcon :val="commandeStores.commandeSelected.variationPrixKWH" />

          <BaseKeyValue :cles="t('priceKiloWattPerHour')">
            <SimpleText :content="commandeStores.commandeSelected.PrixElectricite" />
          </BaseKeyValue>
        </div>

        <div class="flex flex-row items-center gap-4">
          <VaryingIcon :val="commandeStores.commandeSelected.variationPrixBobine" />

          <BaseKeyValue :cles="t('priceCoilKilo')">
            <SimpleText :content="commandeStores.commandeSelected.PrixBobine" />
          </BaseKeyValue>
        </div>

        <BaseKeyValue :cles="t('priceProduction')" v-show="prixProductionIsNotZero">
          <SimpleText :content="commandeStores.commandeSelected.cout.production" />
        </BaseKeyValue>

        <BaseKeyValue :cles="t('totalPrice')" v-show="prixTotalIsNotZero">
          <SimpleText :content="commandeStores.commandeSelected.cout.total" />
        </BaseKeyValue>

        <BlueButton :text="t('downloadInvoice')"
            v-if="isTerminerOuTerminerEtPayer"
            @click="downloadFacture"/>
      </div>

    </div>
  </div>

  <div class="flex flex-row flex-wrap justify-evenly mt-8">
    <IdCardProduit v-for="produit of commandeStores.commandeSelected.produits" :produit="produit"
      :etatCommande="commandeStores.commandeSelected.etat.libelle" :key="produit.uuid" class="w-[18%] h-full"
      :showCreateFailedModal="showCreateFailedModal" :show3DRenderModalByUuid="show3DRenderModalByUuid"
      :show3DRenderModalByBase64="show3DRenderModalByBase64"/>
 </div>
</template>

<script setup>

import {CommandeStores} from "@/stores/CommandeStores";
import {computed, defineProps, ref} from 'vue';
import IdCardProduit from "@/components/organismes/IdCardProduit.vue";
import EtatChips from "@/components/molecules/EtatChips.vue";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from '../../Enumeration';
import {BobineStore} from "@/stores/BobineStore";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import ModalCreationFailedObjectComponent from "@/components/organismes/ModalCreationFailedObjectComponent.vue";
import Modal3DRender from "@/components/organismes/Modal3DRender.vue";
import {CouleurStore} from "@/stores/CouleurStore";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import EuroIcon from "@/components/atoms/EuroIcon.vue";
import EuroBarrerIcon from "@/components/atoms/EuroBarrerIcon.vue";
import {router} from "@/routeur";
import VaryingIcon from "@/components/molecules/VaryingIcon.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});

const commandeStores = CommandeStores();
const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const bobineStore =  BobineStore();
const imprimanteStore = ImprimantesStore();
const couleurStore = CouleurStore();

bobineStore.loadBobines();
imprimanteStore.loadImprimantes();
couleurStore.loadCouleurs();

const isDownloadFacture = ref(false);
const isModalCreateFailedVisible = ref("");
let produitModalFailed = computed(() =>
    commandeStores.commandeSelected.produits.find(produit => produit.uuid === isModalCreateFailedVisible.value)
);
const uuidProduitToRender3D = ref("");
const base64ProduitToRender3D = ref("");

const show3DRenderModal = computed(() => uuidProduitToRender3D.value !== '' || base64ProduitToRender3D.value !== '');
const isAdmin = computed(() => utilisateurStore.role === enumeration.role.ADMIN);
const isTerminerEtPayer = computed(() => commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE_ET_PAYEE);
const isTerminer = computed(() => commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE);
const isEnCour = computed(() => commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.EN_COURS);
const isAFaire = computed(() => commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.A_FAIRE);
const isAdminEtTerminer = computed(() => isAdmin.value && isTerminer.value);
const oneProduitIsNotPrinted = computed(() => commandeStores.commandeSelected.produits.some(produit => produit.etat.libelle !== enumeration.etatObjet.A_IMPRIMER));
const isAdminEtEnCourOrAFaireAvecAucunProduitAFaire = computed(() =>
    isAdmin.value && (isEnCour.value || isAFaire.value) && !oneProduitIsNotPrinted.value
);
const prixProductionIsNotZero = computed(() => commandeStores.commandeSelected.cout.production > 0);
const prixTotalIsNotZero = computed(() => commandeStores.commandeSelected.cout.total > 0);
const isTerminerOuTerminerEtPayer = computed(() => isTerminer.value || isTerminerEtPayer.value);

commandeStores.resetSelected();
commandeStores.getCommandeById(props.uuid);

const openUserStats = (uuid) => {
  router.push({name: 'Stats', params: {uuid: uuid}});
}

const dateFormater = computed(() => {
  if(commandeStores.commandeSelected.dateDebut === "") {
    return '';
  }
  const dateObj = new Date(commandeStores.commandeSelected.dateDebut);

  const jour = dateObj.getUTCDate().toString().padStart(2, '0');
  const mois = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  const annee = dateObj.getUTCFullYear();

  return `${jour}-${mois}-${annee}`;
});

const showCreateFailedModal = (uuidProduit) => {
  isModalCreateFailedVisible.value = uuidProduit;
}

const show3DRenderModalByUuid = (uuidProduit) => {
  uuidProduitToRender3D.value = uuidProduit;
}

const show3DRenderModalByBase64 = (base64) => {
  base64ProduitToRender3D.value = base64;
}

const hideCreateFailedModal = () => {
  isModalCreateFailedVisible.value = "";
}

const hideModal3DRender = () => {
  uuidProduitToRender3D.value = "";
  base64ProduitToRender3D.value = "";
}

const downloadFacture = async () => {
  if(isDownloadFacture.value) {
    return;
  }

  isDownloadFacture.value = true;

  xhr.addRequestToQueue("GET", `/commandes/${commandeStores.commandeSelected.uuid}/pdf`, undefined,
  true, true, true, (response) => {
    if(response.code !== 200) {

      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringDownloadInvoice"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      const blob = new Blob([response.response], {type: 'application/pdf'});

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', commandeStores.commandeSelected.nom  + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      isDownloadFacture.value = false;
    }
  });
}

const setToStatusPayed = async () => {

  xhr.addRequestToQueue("PUT", `/commandes/${commandeStores.commandeSelected.uuid}/etat`,
  {etat: enumeration.etatCommande.TERMINEE_ET_PAYEE}, true, true, false, (response) => {
    if(response.code !== 200) {

      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringServerConnexion"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      notificationStore.addNotification(
          t("orderStatusUpdated"),
          "success");

      commandeStores.updateCommandeStatusBySelected(enumeration.etatCommande.TERMINEE_ET_PAYEE);
    }
  });
}

const setToStatusCancel = async () => {

  xhr.addRequestToQueue("PUT", `/commandes/${commandeStores.commandeSelected.uuid}/etat`,
  {etat: enumeration.etatCommande.ANNULEE}, true, true, false, (response) => {
    if(response.code !== 200) {

      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringServerConnexion"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      notificationStore.addNotification(
          t("orderStatusUpdated"),
          "success");

      commandeStores.updateCommandeStatusBySelected(enumeration.etatCommande.ANNULEE);
      commandeStores.commandeSelected.produits.forEach(produit => {
        produit.etat.libelle = enumeration.etatObjet.ANNULE;
      });
    }
  });
}

const toogleEstGratuit = async () => {

  if(utilisateurStore.role !== enumeration.role.ADMIN) {
    return;
  }

  xhr.addRequestToQueue("PUT", `/commandes/${commandeStores.commandeSelected.uuid}/free`, undefined, true, true, false, (response) => {
    if(response.code !== 200) {

      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringServerConnexion"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      commandeStores.commandeSelected.estGratuite = !commandeStores.commandeSelected.estGratuite;

      notificationStore.addNotification(
          JSON.parse(response.response).message,
          "success");

      if(commandeStores.commandeSelected.estGratuite && commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE) {
        commandeStores.updateCommandeStatusBySelected(enumeration.etatCommande.TERMINEE_ET_PAYEE);
      } else if(!commandeStores.commandeSelected.estGratuite && commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE_ET_PAYEE) {
        commandeStores.updateCommandeStatusBySelected(enumeration.etatCommande.TERMINEE);
      }

      commandeStores.updateCommandeIsFreeBySelected(commandeStores.commandeSelected.estGratuite);
    }
  });
}

</script>

<style scoped>

</style>