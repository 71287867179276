import {defineStore} from 'pinia';
import {NotificationStores} from "@/stores/NotificationStores";
import xhr from "@/utils/xhr";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration.js";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

export const ImprimantesStore = defineStore("ImprimantesStore", {
    state: () => ({
        imprimantes: []
    }),
    actions: {
        async loadImprimantes() {
            const utilisateurStore = UtilisateurStores();
            if(this.imprimantes.length > 0 || utilisateurStore.role !== enumeration.role.ADMIN) {
                return;
            }

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/imprimantes", undefined, true, true, false,
            (response) => {
                if(response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetPrinters"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.imprimantes = JSON.parse(response.response);
                }
            });
        },

        async deleteImprimante(uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("DELETE", "/imprimantes/" + uuid, undefined, true, true, false,
            (response) => {
                if(response.code !== 204) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringDeletePrinter"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    notificationStore.addNotification(t("printerDeleted"), 'success');
                    this.imprimantes = this.imprimantes.filter(imprimante => imprimante.uuid !== uuid);
                }
            });
        },

        async createImprimante(imprimante, hasCreate) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("POST", "/imprimantes", imprimante, true, true, false,
            (response) => {
                if(response.code !== 201) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringCreatePrinter"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.imprimantes.push(JSON.parse(response.response));
                    hasCreate();

                    notificationStore.addNotification(t("printerCreated"), 'success');
                }
            });
        },

        async modifierImprimante(uuid, marque, modele, consommation, prix, urlImage, onSuccess) {
            const notificationStore = NotificationStores();

            const data = {marque: marque, modele: modele, consommation: consommation, prix: prix, urlImage: urlImage};

            xhr.addRequestToQueue("PUT", "/imprimantes/" + uuid, data, true, true, false,
            (response) => {
                if(response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringEditPrinter"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    const ImprimanteStore = ImprimantesStore();
                    const indexImprimante = ImprimanteStore.imprimantes.findIndex(imprimante => imprimante.uuid === uuid);

                    ImprimanteStore.imprimantes[indexImprimante] = { ...ImprimanteStore.imprimantes[indexImprimante],
                        marque, modele, consommation, prix, urlImage };

                    onSuccess();

                    notificationStore.addNotification(t("printerEdited"), 'success');
                }
            });
        },

        async getStatsByUuidPrinter(uuid) {
            return new Promise((resolve, reject) => {
                const notificationStore = NotificationStores();

                xhr.addRequestToQueue("GET", "/imprimantes/stats/" + uuid, undefined, true, true, false,
                    (response) => {
                        if(response.code !== 200) {
                            if(response.code === 0 || !JSON.parse(response.response)) {
                                notificationStore.addNotification(
                                    t("errorDuringGetStats"),
                                    "error");

                                reject();
                            } else {
                                notificationStore.addNotification(
                                    JSON.parse(response.response).message,
                                    "error");

                                reject();
                            }
                        } else {
                            resolve(JSON.parse(response.response));
                        }
                    });
            });
        }
    },
    getters : {}
});
