<template>
  <ModalCreateReparation :isVisible="showCreateReparationModal" :close-modal="closeModalCreationReparation" />

  <div class="flex flex-col md:flex-row items-center justify-center gap-8 bg-white py-4 border-b-2 shadow-xl rounded-b-[2.5rem]"
  :class="{'border-red-600': !estEnBenefice, 'border-green-600': estEnBenefice}">
    <img :src="stats.value.imprimante.urlImage" alt="" class="h-64">
    <div class="flex flex-col items-start">
      <BaseKeyValue :cles="t('model')" size="text-3xl">
        <SimpleText :content="stats.value.imprimante.modele" size="text-3xl" />
      </BaseKeyValue>

      <BaseKeyValue :cles="t('brand')" size="text-xl">
        <SimpleText :content="stats.value.imprimante.marque" size="text-xl" />
      </BaseKeyValue>

      <BaseKeyValue :cles="t('consommation')">
        <SimpleText :content="stats.value.imprimante.consommation + ' ' + t('watts')" />
      </BaseKeyValue>

      <BaseKeyValue :cles="t('initialPrice')">
        <SimpleText :content="stats.value.imprimante.prix + '€'" />
      </BaseKeyValue>

      <BaseKeyValue :cles="t('repairPrice')">
        <SimpleText :content="converter.round(coutReparations) + '€'" />
      </BaseKeyValue>

      <BlueClickableText :text="t('addRepair')" @click="createReparation" />
    </div>
  </div>

  <div class="mt-4 flex flex-row justify-center items-center" @click="() => {showReparation = !showReparation}"
       v-show="printerHasRepairs">
    <span class="mr-2 select-none">{{t("showRepairs")}}</span>
    <BlackUpIcon v-show="!showReparation"/>
    <BlackDownIcon v-show="showReparation"/>
  </div>

  <div v-show="showReparation" class="flex flex-wrap mx-8">
    <IdCardReparation v-for="reparation in stats.value.imprimante.reparations" :reparationProps="reparation" :key="reparation.uuid" :delete-reparation="supprimerReparation"/>
  </div>


  <div class="flex flex-col md:flex-row flex-wrap items-center justify-center mx-4 md:mx-16 mt-8">

    <IdCardStats :titre="t('price')"
     :stats="[
         {nom: t('priceProduction'), valeur: converter.round(stats.value.stats.coutImpression) + ' €'},
         {nom: t('avgProductPriceParPiece'), valeur: converter.round(stats.value.stats.avgCoutParPiece) + ' €'},
         {nom: t('profit'), valeur: converter.round(stats.value.stats.beneficeImpression) + ' €'}
     ]"/>

    <IdCardStats :titre="t('time')"
                 :stats="[
         {nom: t('totalTime'), valeur: converter.minToHours(stats.value.stats.tempsTotalImpression)},
         {nom: t('avgTimeParPiece'), valeur: converter.minToHours(stats.value.stats.avgTempsParPiece)},
     ]"/>

    <IdCardStats :titre="t('consumption')"
                 :stats="[
         {nom: t('consumptionKwh'), valeur: converter.round(stats.value.stats.nbKwhUtiliser) + ' KW/H'},
         {nom: t('totalPriceKwhConsumption'), valeur: converter.round(stats.value.stats.prixKwhConsommer) + ' €'},
     ]"/>

    <IdCardStats :titre="t('products')"
                 :stats="[
         {nom: t('numberOfProducts'), valeur: stats.value.stats.nbTotalPieces},
     ]"/>
  </div>

  <div class="flex flex-col items-center mt-8 gap-8 w-full mb-8">
    <PieStats :stats="bobinePieStat" :titre="t('coilUsage')" v-if="bobinePieStat.length > 0"
        class="w-10/12 lg:w-1/2"/>

    <DateVerticalBarsStat :stats="stats.value.stats.produitImprimerParMois" :titre="t('productFor3Years')"
      class="w-11/12 lg:w-3/5"/>
  </div>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {ArcElement, Chart, Legend, PieController, registerables, Tooltip} from "chart.js";
import {computed, defineProps, reactive, ref} from "vue";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import converter from "@/utils/Converter";
import ModalCreateReparation from "@/components/organismes/ModalCreateReparation.vue";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import IdCardReparation from "@/components/organismes/IdCardReparation.vue";
import BlueClickableText from "@/components/molecules/BlueClickableText.vue";
import BlackUpIcon from "@/components/atoms/BlackUpIcon.vue";
import BlackDownIcon from "@/components/atoms/BlackDownIcon.vue";
import IdCardStats from "@/components/organismes/IdCardStats.vue";
import PieStats from "@/components/molecules/PieStats.vue";
import DateVerticalBarsStat from "@/components/molecules/DateVerticalBarsStat.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const imprimanteStore = ImprimantesStore();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStore.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/"});
}



const printerHasRepairs = computed(() => stats.value.imprimante.reparations && stats.value.imprimante.reparations.length > 0);

Chart.register(PieController, ArcElement, Tooltip, Legend, ...registerables);

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});


let stats = reactive({value: {
    imprimante: {uuid: "", marque: "", modele: "", consommation: 0, prix: 0, reparations: []},
    stats: {
      tempsTotalImpression: 0,
      avgTempsParPiece: 0,
      nbTotalPieces: 0, // piece
      coutImpression: 0,
      beneficeImpression: 0,
      avgCoutParPiece: 0,
      nbKwhUtiliser: 0,
      prixKwhConsommer: 0,
      couleurs: [],
      produitImprimerParMois: {},
    }
  }});

const coutReparations = computed(() => {
  return stats.value.imprimante.reparations.reduce((acc, reparation) => acc + reparation.prix, 0);
});

imprimanteStore.getStatsByUuidPrinter(props.uuid).then((response) => {
  stats.value = response;
});

const estEnBenefice = computed(() => {
  return stats.value.stats.beneficeImpression > (stats.value.imprimante.prix + coutReparations.value);
});

const showCreateReparationModal = ref(false);
const showReparation = ref(false);

const createReparation = () => {
  showCreateReparationModal.value = true;
}

const closeModalCreationReparation = (reparation) => {

  if(showCreateReparationModal.value) {
    showCreateReparationModal.value = false;
  }

  if(reparation) {
    xhr.addRequestToQueue("POST", "/reparations",  { ...reparation, uuidImprimante: props.uuid }, true, true, false,
      (response) => {
        if(response.code !== 201) {
          if (response.code === 0 || !jsonValidator.validate(response.response)) {
            notificationStore.addNotification(
                t("errorDuringCreateRepair"),
                "error");
          } else {
            notificationStore.addNotification(
                JSON.parse(response.response).message,
                "error");
          }
        } else {
          stats.value.imprimante.reparations.unshift(JSON.parse(response.response));
          notificationStore.addNotification(t("repairCreated"), "success");
        }
      });
  }
}

const supprimerReparation = (uuid) => {
  stats.value.imprimante.reparations = stats.value.imprimante.reparations.filter((reparation) => reparation.uuid !== uuid);
}

const bobinePieStat = computed(() => {
  return stats.value.stats.couleurs.map((couleur) => {
    return {value: couleur.total, color: couleur.code, text: couleur.libelle};
  });
});

</script>

<style scoped>

</style>