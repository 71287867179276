<template>
  <BaseIdCard :numberPerRow="4">
    <div class="flex flex-row justify-between gap-2">
      <div class="flex flex-col">
        <TitreDeux :content='t("coil") + " " + bobine.nombre' fontSemiBold />

        <IdCardBobineReading v-if="!isEditing" :bobine="bobine" />
        <IdCardBobineEditing ref="editingChild" v-if="isEditing" :bobine="bobine" />
      </div>

      <div class="flex flex-col items-start h-full">
        <OrangeWarnIcon v-show="!bobine.isDefectueuse" @click="toogleDefective" />
        <BlueWarnIcon v-show="bobine.isDefectueuse" @click="toogleDefective" />

        <GreenCheckIcon v-if="isEditing" @click="toogleEditMode" />
        <GreenEditIcon v-if="!isEditing" @click="toogleEditMode" />
        <RedTrashIcon @click="props.showModalDelete(bobine.uuid, bobine.poidsRestant)" class="mt-2" />
      </div>
    </div>

    <InfoChip v-show="bobine.isDefectueuse" :text='t("presumedDefective")' couleur="bg-orange-600 mt-2" />
  </BaseIdCard>
</template>

<script setup>
import {defineProps, ref, useTemplateRef} from "vue";
import IdCardBobineReading from "@/components/organismes/IdCardBobineReading.vue";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {NotificationStores} from "@/stores/NotificationStores";
import {BobineStore} from "@/stores/BobineStore";
import {t} from "@/utils/Traduction";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import InfoChip from "@/components/molecules/InfoChip.vue";
import OrangeWarnIcon from "@/components/atoms/OrangeWarnIcon.vue";
import BlueWarnIcon from "@/components/atoms/BlueWarnIcon.vue";
import IdCardBobineEditing from "@/components/organismes/IdCardBobineEditing.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import GreenEditIcon from "@/components/atoms/GreenEditIcon.vue";
import GreenCheckIcon from "@/components/atoms/GreenCheckIcon.vue";

const bobineStore = BobineStore();
const notificationStore = NotificationStores();

const props = defineProps({
  bobine: {
    type: Object,
    required: true
  },
  showModalDelete: {
    type: Function,
    required: true
  },
});

const editingChildRef = useTemplateRef('editingChild');
const isEditing = ref(false);

const toogleEditMode = () => {

  if(isEditing.value) {
    editingChildRef.value.saveChanges();
  }

  isEditing.value = !isEditing.value;
}

const toogleDefective = () => {
  xhr.addRequestToQueue("PUT", `/bobines/${props.bobine.uuid}/defective`, undefined, true, true, false,
  (response) => {
    if(response.code !== 204) {
      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorSetCoilDefective"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      const bobine = bobineStore.bobines.find(bobine => bobine.uuid === props.bobine.uuid)

      if(bobine.isDefectueuse) {
        bobine.isDefectueuse = false;
        notificationStore.addNotification(
            t("successSetCoilUndefective"),
            "success");

      } else {
        bobine.isDefectueuse = true;
        notificationStore.addNotification(
            t("successSetCoilDefective"),
            "success");
      }
    }
  });
}

</script>

<style scoped>

</style>