<template>
  <div class="flex flex-col gap-4">
    <TitreDeux :content='t("bobineList")' />

    <div class="flex flex-row items-center gap-4">
      <TitreDeux :content='t("filter")' fontSemiBold />
      <DropDownField :placeholder="t('filter')"
         :values="couleurAvailableForFilter" v-model="filtreBobine" />
    </div>

    <div class="flex flex-row items-center gap-4">
      <TitreDeux :content='t("sort") + ":"' fontSemiBold />

      <DropDownField :placeholder='t("chooseSort")'
          :values='[
              {id: "number", display: t("sortByNumber")},
              {id: "weight", display: t("sortByWeight")}
          ]'
          v-model="trieBobine"/>

      <DropDownField :placeholder='t("chooseSort")'
        :values='[
            {id: "asc", display: t("asc")},
            {id: "desc", display: t("desc")}
        ]'
        v-model="typeTrie"/>
    </div>

    <div class="flex flex-wrap">
      <IdCardBobine v-for="bobine of bobines.value" :key="bobine.uuid" :bobine="bobine" :show-modal-delete="showModalDelete" />
    </div>


    <BaseDeleteModal :text="t('attentionAlertDeleteCoil') + ' ' + grammesModale + ' ' + t('attentionAlertDeleteCoilEnd')"
       :isVisible="showModal" @closeModal="closeModalDelete" />
    <ModalCreationCouleurComponent :isVisible="couleurStore.showModalCreation"/>
  </div>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import {CouleurStore} from "@/stores/CouleurStore";
import {BobineStore} from "@/stores/BobineStore";
import IdCardBobine from "@/components/organismes/IdCardBobine.vue";
import ModalCreationCouleurComponent from "@/components/organismes/ModalCreationCouleurComponent.vue";
import BaseDeleteModal from "@/components/molecules/BaseDeleteModal.vue";

const couleurStore = CouleurStore();
const bobineStore = BobineStore();

const trieBobine = ref('number');
const filtreBobine = ref(-1);
const typeTrie = ref('asc');
const bobines = reactive([]);
const grammesModale = ref(0);
const uuidBobineModale = ref(0);
const showModal = ref(false);

const couleurAvailableForFilter = computed(() => {
  const unformated = couleurStore.couleurs.filter(couleur =>
      bobineStore.bobines.find(bobine => bobine.couleur.uuid === couleur.uuid)
  );

  const formated = unformated.map(couleur => {
    return {
      id: couleur.uuid,
      display: couleur.libelle
    }
  });

  formated.unshift({id: -1, display: t("all")});
  return formated;
});

watch(() => filtreBobine.value, () => {
  applyFilterAndSort();
}, { deep: true });

watch(() => trieBobine.value, () => {
  applyFilterAndSort();
}, { deep: true });

watch(() => typeTrie.value, () => {
  applyFilterAndSort();
}, { deep: true });

watch(() => bobineStore.bobines, () => {
  applyFilterAndSort();
}, { deep: true });

onMounted(() => {
  applyFilterAndSort();
})

function applyFilterAndSort() {
  bobines.value = bobineStore.bobines.filter(bobine =>
      bobine.couleur.uuid === filtreBobine.value || filtreBobine.value === -1
  );

  bobines.value.sort((a, b) => {
    if(trieBobine.value === 'number') {
      return typeTrie.value === 'asc' ? a.nombre - b.nombre : b.nombre - a.nombre;
    } else {
      return typeTrie.value === 'asc' ? a.poidsRestant - b.poidsRestant : b.poidsRestant - a.poidsRestant;
    }
  });
}

const closeModalDelete = (supprimer) => {
  if(supprimer) {
    bobineStore.deleteBobine(uuidBobineModale.value);
    bobineStore.bobines = bobineStore.bobines.filter(bobine => bobine.uuid !== uuidBobineModale.value);
  }

  showModal.value = false;
}

const showModalDelete = (uuid, grammes) => {
  grammesModale.value = grammes;
  showModal.value = true;
  uuidBobineModale.value = uuid;
}
</script>

<style scoped>

</style>