<template>
  <BaseModal :isVisible="isVisible">
    <TitreDeux :content="t('addFailedObjet')" />

    <SimpleText v-show="errorMessage !== ''" :content="errorMessage" color="text-red-600" />

    <BaseKeyValue :cles="t('weights')">
      <InputField :placeholder="t('weightInGrammes')" v-model="poid" type="number" :min="1" />
    </BaseKeyValue>

    <BaseKeyValue :cles="t('time')">
      <InputField :placeholder="t('time')"  v-model="temps" :min="1"
            @onChange="resolveMathTemps" />
    </BaseKeyValue>

    <BaseKeyValue :cles="t('coil')">
      <DropDownField :placeholder="t('chooseACoil')" v-model="bobineUsed"
         :values="bobinesDeLaCouleurDuProduit.map(b => ({
            id: b.uuid,
            display: `${b.nombre} - ${b.couleur.libelle} - ${b.poidsRestant}g`
         }))" />
    </BaseKeyValue>

    <BaseKeyValue :cles="t('printer')">
      <DropDownField :placeholder="t('chooseAPrinter')" v-model="printerUsed"
         :values="imprimanteStore.imprimantes.map(p => ({
            id: p.uuid,
            display: `${p.marque} ${p.modele}`
         }))" />
    </BaseKeyValue>

    <BaseKeyValue :cles="t('cause')">
      <TextAreaInput :placeholder="t('causeOfFailed')" v-model="cause" showLabel />
    </BaseKeyValue>

    <div class="flex flex-row-reverse items-center justify-start gap-4">
      <BlueButton :text="t('validate')" @click="validate" />
      <BlackClickableText :text="t('cancelAction')" @click="props.hideModal" />
    </div>
  </BaseModal>
</template>

<script setup>

import {computed, defineProps, ref} from 'vue';
import {t} from '@/utils/Traduction';
import {parser} from "mathjs";
import {BobineStore} from "@/stores/BobineStore";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import {FailedStore} from "@/stores/FailedStore";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import BlackClickableText from "@/components/molecules/BlackClickableText.vue";
import BaseModal from "@/components/molecules/BaseModal.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import TextAreaInput from "@/components/molecules/TextAreaInput.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import InputField from "@/components/molecules/InputField.vue";

const props = defineProps({
  produit: {
    type: Object,
    required: true,
  },

  hideModal: {
    type: Function,
    required: true,
  },
  isVisible: {
    type: Boolean,
    required: true,
  }
});

const bobineStore = BobineStore();
const imprimanteStore = ImprimantesStore();
const failedStore = FailedStore();

const bobinesDeLaCouleurDuProduit = computed(() => {
  const filteredBobines = props.produit ? bobineStore.bobines.filter(bobine => bobine.couleur.uuid === props.produit.couleur.uuid) : [];
  // Si aucune bobine correspondante n'est trouvée, retourner toutes les bobines
  return filteredBobines.length > 0 ? filteredBobines : bobineStore.bobines;
});


const poid = ref();
const temps = ref("");
const bobineUsed = ref("-1");
const printerUsed = ref("-1");
const cause = ref("");
const errorMessage = ref("");

const resolveMathTemps = () => {
  try {
    const resultat = parser().evaluate(temps.value);

    if(resultat <= 0) {
      temps.value = 1;
    } else {
      temps.value = resultat;
    }
  } catch(e) {
    console.log("not good syntax");
  }
}

const validate = () => {
  if(!poid.value || poid.value <= 0) {
    errorMessage.value = t("weightInfZero");
  } else if(!temps.value || temps.value <= 0) {
    errorMessage.value = t("TimeInfZero");
  } else if(bobineUsed.value === "-1") {
    errorMessage.value = t("needFillCoil");
  } else if(printerUsed.value === "-1") {
    errorMessage.value = t("needFillPrinter");
  } else if(cause.value === '') {
    errorMessage.value = t("needFillCause");
  } else {

    failedStore.create(poid.value, temps.value, bobineUsed.value, printerUsed.value, cause.value, props.produit.uuid,
        () => {
          poid.value = null;
          temps.value = null;
          bobineUsed.value = "-1";
          printerUsed.value = "-1"
          cause.value = "";

          props.hideModal();
        });

  }
}

</script>

<style scoped>

</style>