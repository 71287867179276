<template>
  <div class="flex flex-col items-center w-[90%] ml-[5%] gap-4">
    <div class="flex flex-row justify-between mx-[5%] md:mx-1 mt-8 w-full">
      <TitreUn v-if="!isAdmin" :content="t('createOrder')" />

      <div v-if="isAdmin" class="flex flex-col md:flex-row items-center w-full gap-2">
        <TitreUn :content="t('createOrderFor')" />

        <DropDownField :placeholder="t('selectAClient')" v-model="uuidClient"
                       :values="utilisateurStore.clients.map(client => ({id: client.uuid, display: client.email}))" />
      </div>

      <div class="flex flex-row h-8 items-center gap-2">
        <GreenPlusIcon @click="addProduit" />
        <SimpleText :content="t('product')" />
      </div>
    </div>

    <InputField :placeholder="t('inputOrderName')" :label="t('orderName')" v-model="nomCommande" class="lg:w-full" showLabel />

    <div class="flex flex-row justify-center flex-wrap w-full gap-4">

      <CreationProduitComponent v-for="produit in produits" :key="produit.uuid" :model="produit"
          @update="produits[produits.findIndex(p => p.uuid === produit.uuid)] = produit"
          @removeProduit="removeProduit" @show3DRenderModal="show3DRenderModal" class="w-[23%]"/>
    </div>

    <BlueButton data-testid="confirmOrder" :text="t('confirmOrder')" @click="validateCommande" class="mb-8" />
  </div>

  <ModalCreationCouleurComponent :isVisible="couleurStore.showModalCreation"/>
  <ModalErreur :texte="texteModalError" :close-modal="() => {isModalErrorVisible = false}" v-show="isModalErrorVisible"/>
  <Modal3DRender :isVisible="renderModal3D !== ''" :hide-modal="close3DModelModal" :base64-data="renderModal3D" />
</template>

<script setup>

import {t} from "@/utils/Traduction";
import CreationProduitComponent from "@/components/organismes/CreationProduitComponent.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import InputField from "@/components/molecules/InputField.vue";
import TitreUn from "@/components/atoms/TitreUn.vue";
import GreenPlusIcon from "@/components/atoms/GreenPlusIcon.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import ModalErreur from "@/components/organismes/ModalErreur.vue";
import ModalCreationCouleurComponent from "@/components/organismes/ModalCreationCouleurComponent.vue";
import Modal3DRender from "@/components/organismes/Modal3DRender.vue";
import {computed, onBeforeMount, onUnmounted, reactive, ref} from "vue";
import {enumeration} from "../../../Enumeration";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {router} from "@/routeur";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {CouleurStore} from "@/stores/CouleurStore";
import {CommandeStores} from "@/stores/CommandeStores";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const couleurStore = CouleurStore();
const commandeStore = CommandeStores();

const nomCommande = ref("");
const uuidClient = ref(-1);
const texteModalError = ref("");
const isModalErrorVisible = ref(false);
const isValidationCommande = ref(false);
const renderModal3D = ref("");
let produits = reactive([]);
const hasCreateCommande = ref(false);

const isAdmin = computed(() => utilisateurStore.role === enumeration.role.ADMIN);

const show3DRenderModal = (fichier) => {
  renderModal3D.value = fichier;
}

const close3DModelModal = () => {
  renderModal3D.value = "";
}

const addProduit = () => {
  produits.push({
    uuid: crypto.randomUUID(),
    nom: "",
    couleur: -1,
    quantite: 1,
    remplissage: 20,
    fichier: {
      nom: "",
      content: "",
    },
    error: false
  });
}

onUnmounted(() => {
  if(!hasCreateCommande.value) {
    commandeStore.commandeEnCreation = {
      nom: nomCommande,
      client: uuidClient,
      produits
    }
  }
});

onBeforeMount(() => {
  if(commandeStore.commandeEnCreation) {
    nomCommande.value = commandeStore.commandeEnCreation.nom;
    uuidClient.value = commandeStore.commandeEnCreation.client;
    produits = commandeStore.commandeEnCreation.produits;

    commandeStore.commandeEnCreation = null;
  } else {
    addProduit();
  }
})

const removeProduit = (uuid) => {
  if(produits.length === 1) {
    notificationStore.addNotification(t("requireMinOneProduct"), 'error');
    return;
  }

  const index = produits.findIndex(produit => produit.uuid === uuid);
  if (index !== -1) {
    produits.splice(index, 1);
  }
}

const showModalError = (texte) => {
  texteModalError.value = texte;
  isModalErrorVisible.value = true;
}

const validateCommande = async () => {
  if(isValidationCommande.value){
    return;
  }

  isValidationCommande.value = true;

  if(nomCommande.value === ""){
    notificationStore.addNotification(t("needFillCommandeName"), 'error');
    showModalError(t("needFillCommandeName"));
    isValidationCommande.value = false;
    return;
  }

  if(utilisateurStore.role === enumeration.role.ADMIN && uuidClient.value === -1){
    notificationStore.addNotification(t("youNeedSelectAClient"), 'error');
    showModalError(t("youNeedSelectAClient"));
    isValidationCommande.value = false;
    return;
  }

  if(produits.length === 0){
    notificationStore.addNotification(t("youNeedToAddMinOneProduct"), 'error');
    showModalError(t("youNeedToAddMinOneProduct"));
    isValidationCommande.value = false;
    return;
  }

  let allProduitAreOk = true;
  for(let produit of produits) {
    if(!produit.nom || produit.nom === "" || !produit.couleur || produit.couleur === -1
        || !produit.quantite || produit.quantite <= 0 || !produit.fichier || produit.fichier.content === "") {

      produit.error = true;

      allProduitAreOk = false;
    }
  }

  if(!allProduitAreOk){
    notificationStore.addNotification(t('youNeedFillAllFieldsOfProduct'), 'error');
    showModalError(t("youNeedFillAllFieldsOfProduct"));
    isValidationCommande.value = false;
    return;
  }

  const produitFormated = [];
  for(let produit of produits) {
    produitFormated.push({
      nom: produit.nom,
      uuidCouleur: produit.couleur,
      nombre: produit.quantite,
      stl: produit.fichier.content,
      remplissage: produit.remplissage
    });
  }

  const commandeFormated = {
    nom: nomCommande.value,
    produits: produitFormated
  };

  const url = "/commandes/create" + (uuidClient.value !== -1 ? "/" + uuidClient.value : "");

  xhr.addRequestToQueue("POST", url, commandeFormated, true, true, false, async (response) => {
    if (response.code !== 201) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringCreateOrder"),
            "error");

        showModalError(t("errorDuringCreateOrderServerError"));


        isValidationCommande.value = false;
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
        isValidationCommande.value = false;
        showModalError(JSON.parse(response.response).message);

      }
    } else {
      notificationStore.addNotification(
          t("orderCreated"),
          "success");
      isValidationCommande.value = false;

      const commandeResponse = JSON.parse(response.response);
      commandeStore.commandes.unshift({
        uuid: commandeResponse.uuid,
        nom: nomCommande.value,
        utilisateur: commandeResponse.utilisateur,
        uuidUtilisateur: commandeResponse.uuidUtilisateur,
        dateDebut: new Date().toISOString(),
        etat: {libelle: enumeration.etatCommande.A_FAIRE},
        produits: produits
      });

      hasCreateCommande.value = true;

      if(isAdmin.value) {
        await router.push({path: "/admin/commandes"});
      } else {
        await router.push({ path: "/commandes"});
      }
    }
  });
}
</script>

<style scoped>

</style>