<template>
  <div class="bg-[#fdf1dd] flex flex-row justify-between items-center px-4 py-2">
    <div class="flex flex-row items-center gap-2">
      <BrownCautionIcon class="text-xl" />
      <SimpleText :content="props.information.message" />
    </div>

    <BrownCrossIcon datatest-id="close" class="text-xl" @click="closeInformation" />
  </div>
</template>

<script setup>

import {defineProps} from "vue";
import {InformationStore} from "@/stores/InformationStore";
import SimpleText from "@/components/atoms/SimpleText.vue";
import BrownCautionIcon from "@/components/atoms/BrownCautionIcon.vue";
import BrownCrossIcon from "@/components/atoms/BrownCrossIcon.vue";

const props = defineProps({
  information: Object
});

const informationStore = InformationStore();

const closeInformation = () => {
  const informationInStore = informationStore.activeInformations.find(information => information.uuid === props.information.uuid);
  informationInStore.show = false;
}

</script>

<style scoped>

</style>