<template>
  <Teleport to="body">
    <div v-if="isVisible" data-testid="modal" class="fixed inset-0 h-screen w-screen flex flex-col items-center justify-center bg-black bg-opacity-20">
      <div class="bg-white shadow-xl rounded-lg w-11/12 lg:w-1/2 p-8 flex flex-col gap-4">
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<script setup>

import {defineProps, onBeforeUnmount, watch} from "vue";

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true
  }
});

watch(() => props.isVisible, (newValue) => {
  if(newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
});

onBeforeUnmount(() => {
  document.body.style.overflow = 'auto';
});

</script>

<style scoped>

</style>