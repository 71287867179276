<template>
  <BaseIdCard :numberPerRow="4">
    <div class="text-center mb-4">
      <TitreDeux v-show="!isEditMode" :content="props.produit.nom" />
      <InputField :placeholder="t('inputProductName')" v-show="isEditMode" v-model="editName"
        class="text-xl font-semibold" />
    </div>
    <div class="flex flex-col">
      <BaseKeyValue :cles="t('color')" >
        <SimpleText :content="produit.couleur.libelle" v-show="!isEditMode" />
        <DropDownField :placeholder="t('selectAColor')" v-model="editColor"
            v-show="isEditMode" :values="couleurStore.couleurs.map(c => ({
            id: c.uuid,
            display: c.libelle
         }))" />
      </BaseKeyValue>

      <BaseKeyValue :cles="t('quantity')">
        <SimpleText :content="produit.nombre" v-show="!isEditMode" />
        <InputField :placeholder="t('inputQuantity')" v-show="isEditMode" v-model="editQuantity"
              :min="1" type="number"/>
      </BaseKeyValue>

      <BaseKeyValue :cles="t('filling')">
        <SimpleText :content="produit.remplissage + '%'" v-show="!isEditMode" />
        <DropDownField :placeholder="t('filling')" v-model="editFilling" v-show="isEditMode"
             :values="utils.getFillingValues()"/>
      </BaseKeyValue>

      <BaseKeyValue :cles="t('weights')" v-if="showPoidValue">
        <SimpleText :content="produit.poid + 'g'" />
      </BaseKeyValue>

      <BaseKeyValue :cles="t('time')" v-if="showTempValue">
        <SimpleText :content="produit.temp" />
      </BaseKeyValue>

      <BaseKeyValue :cles="t('printer')" v-if="showPrinterValue">
        <SimpleText :content="produit.imprimante.marque + ' ' + produit.imprimante.modele" />
      </BaseKeyValue>

      <etat-chips v-if="!isEditMode" :etat="props.produit.etat" />

      <div v-show="showAdminButtonEnImpression">
        <BaseKeyValue :cles="t('weights')">
          <InputField :placeholder="t('weights')" v-model="poidEdit"
            type="number" :min="1"/>
        </BaseKeyValue>

        <BaseKeyValue :cles="t('time')">
          <InputField :placeholder="t('time')" v-model="tempsEdit"
            :min="1" @onChange="resolveMathTemps"/>
        </BaseKeyValue>

        <BaseKeyValue :cles="t('coil')"  v-show="poidEdit > 0">
          <BlueClickableText :text="t('selectCoil')" @click="() => showModalBobine = true"/>
        </BaseKeyValue>

        <BaseKeyValue :cles="t('printer')">
          <DropDownField :placeholder="t('chooseAPrinter')" v-model="printerUsed"
             :values="imprimanteStore.imprimantes.map(p => ({
                id: p.uuid,
                display: `${p.marque} ${p.modele}`
             }))" />
        </BaseKeyValue>

        <BlueClickableText :text="t('addAFailedObject')" @click="showCreateFailedModal(produit.uuid)" class="w-full flex flex-row justify-center" />
      </div>

      <div v-show="showAdminButtonAFaire" class="flex flex-col justify-center gap-2 mt-2">
        <YellowButton v-if="isAdmin" @click="setStatusWIP" :text="t('changeToPrinting')" />
        <GreyButton @click="setStatusCancel" :text="t('cancelProduct')" />
      </div>
    </div>

    <div class="flex flex-row justify-center items-center gap-2" @click="() => {showFailedPrint = !showFailedPrint}"
      v-if="hasFailed">
      <SimpleText :content="t('showFailedPrint')" />
      <BlackUpIcon v-show="!showFailedPrint"/>
      <BlackDownIcon v-show="showFailedPrint"/>
    </div>

    <IdCardFailed v-show="showFailedPrint" v-for="failed of produit.failed" :key="failed.uuid" :failed="failed"/>

    <div class="mt-4 text-center grid grid-cols-2 gap-2">
      <BlueButton :text="t('downloadStl')" v-show="!isEditMode" @click="downloadStl" />

      <PurpleFileInputField
        v-show="isEditMode"
        v-model="editValueStl"
        :defaultValue="editValueStl.name"
        class="col-span-2"/>

      <BlueButton :text="t('show3D')" @click="show3DRenderModal" />
      <BlueButton v-show="isNotEditModeEtAFaire"
          :text="t('editAction')" @click="isEditMode = true" class="col-span-2" />
      <BlueButton @click="updateProduit" v-show="isEditMode" :text="t('validate')" />
    </div>

    <div v-show="!isEditMode" @click="showAdminButton" class="absolute right-4 top-1/5 bottom-1/2 text-xl text-blue-500 cursor-pointer">
      <BlueRightArrowIcon v-show="needRenderAdminButton" />
    </div>
    <div v-show="isEnImpression" @click="setToPrinted" class="absolute right-4 top-1/5 bottom-1/2 text-xl text-blue-500 cursor-pointer">
      <BlueCheckIcon v-show="isAdminAndShowAdminButton" />
    </div>
  </BaseIdCard>

  <ModalChoixBobinePourProduit ref="modalElement" :isVisible="showModalBobine" :close-modal="closeModalSelectedBobine" :nb-produit="produit.nombre" :poid-produit="poidEdit" :bobines-de-la-couleur="bobinesDeLaCouleurDuProduit" />
</template>

<script setup>
import {computed, defineProps, reactive, ref, useTemplateRef, watch} from 'vue';
import EtatChips from "@/components/molecules/EtatChips.vue";
import xhr from "@/utils/xhr";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {CommandeStores} from "@/stores/CommandeStores";
import {enumeration} from '../../../Enumeration';
import {BobineStore} from "@/stores/BobineStore";
import {parser} from "mathjs";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import {ProduitStores} from "@/stores/ProduitStore";
import ModalChoixBobinePourProduit from "@/components/organismes/ModalChoixBobinePourProduit.vue";
import {CouleurStore} from "@/stores/CouleurStore";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import InputField from "@/components/molecules/InputField.vue";
import BlueClickableText from "@/components/molecules/BlueClickableText.vue";
import YellowButton from "@/components/molecules/YellowButton.vue";
import GreyButton from "@/components/molecules/GreyButton.vue";
import BlackUpIcon from "@/components/atoms/BlackUpIcon.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import BlackDownIcon from "@/components/atoms/BlackDownIcon.vue";
import IdCardFailed from "@/components/organismes/IdCardFailed.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import PurpleFileInputField from "@/components/molecules/PurpleFileInputField.vue";
import BlueRightArrowIcon from "@/components/atoms/BlueRightArrowIcon.vue";
import BlueCheckIcon from "@/components/atoms/BlueCheckIcon.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import {utils} from "@/utils/other";

const props = defineProps({
  produit: Object,
  etatCommande: String,
  showCreateFailedModal: Function,
  show3DRenderModalByUuid: Function,
  show3DRenderModalByBase64: Function
});

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const commandeStore = CommandeStores();
const bobineStore =  BobineStore();
const imprimanteStore = ImprimantesStore();
const produitStore = ProduitStores();
const couleurStore = CouleurStore();

const isShowingAdminButton = ref(false);
const isEditMode = ref(false);
const poidEdit = ref();
const tempsEdit = ref();
const printerUsed = ref(-1);
const isDownloadStl = ref(false);
const showFailedPrint = ref(false);
const selectedBobines = reactive({full: [], partial: []});
const showModalBobine = ref(false);
const editValueStl = ref({name: props.produit.nom, content: ""});
const editName = ref(props.produit.nom);
const editColor = ref(props.produit.couleur.uuid);
const editQuantity = ref(props.produit.nombre);
const editFilling = ref(props.produit.remplissage);
const modalElementRef = useTemplateRef("modalElement");

const isAFaire = computed(() => props.produit.etat.libelle === enumeration.etatObjet.A_IMPRIMER);
const isEnImpression = computed(() => props.produit.etat.libelle === enumeration.etatObjet.EN_IMPRESSION);
const isAdmin = computed(() => utilisateurStore.role === enumeration.role.ADMIN);
const hasPoid = computed(() => props.produit.poid !== 0);
const hasTemp = computed(() => props.produit.temp !== 0);
const showPoidValue = computed(() => hasPoid.value && !isShowingAdminButton.value);
const showTempValue = computed(() => hasTemp.value && !isShowingAdminButton.value);
const showPrinterValue = computed(() => props.produit.imprimante && !isShowingAdminButton.value);
const showAdminButtonEnImpression = computed(() => isShowingAdminButton.value && isEnImpression.value);
const showAdminButtonAFaire = computed(() => !isEditMode.value && isShowingAdminButton.value && isAFaire.value)
const hasFailed = computed(() => props.produit.failed && props.produit.failed.length > 0);
const isNotEditModeEtAFaire = computed(() => !isEditMode.value && isAFaire.value);
const isAdminAndShowAdminButton = computed(() => isAdmin.value && isShowingAdminButton.value)
const needRenderAdminButton = computed(() =>
    (isAdmin.value && !isEditMode.value && !isShowingAdminButton.value && (isAFaire.value || isEnImpression.value))
    || (!isAdmin.value && isAFaire.value && !isShowingAdminButton.value)
)



const bobinesDeLaCouleurDuProduit = computed(() => {
  const filteredBobines = bobineStore.bobines.filter(bobine => bobine.couleur.uuid === props.produit.couleur.uuid);
  // Si aucune bobine correspondante n'est trouvée, retourner toutes les bobines
  return filteredBobines.length > 0 ? filteredBobines : bobineStore.bobines;
});

watch(poidEdit, () => {
  selectedBobines.full = [];
  selectedBobines.partial = [];
  if (modalElementRef.value) {
    modalElementRef.value.reset();
  }
});

const show3DRenderModal = () => {
  if(editValueStl.value.content !== "" && isEditMode.value) {
    props.show3DRenderModalByBase64(editValueStl.value.content);
  } else {
    props.show3DRenderModalByUuid(props.produit.uuid);
  }
}

const downloadStl = async () => {
  produitStore.getStl(props.produit.uuid, (response) => {
    const stlFormated = response.response

    const link = document.createElement('a');
    link.href = stlFormated
    link.download = props.produit.nom + ".stl";
    link.click();

    isDownloadStl.value = false;
  });
}

const showAdminButton = () => {
  isShowingAdminButton.value = true;
}

const setStatusWIP = async () => {
  xhr.addRequestToQueue("PUT", `/produits/${props.produit.uuid}/wip`, undefined, true, true, false,
    (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("productStatusUpdated"),
            "success");

        const newProduit = {...(props.produit)};
        newProduit.etat.libelle = enumeration.etatObjet.EN_IMPRESSION;
        commandeStore.updateProduitInCommandeSelected(newProduit);
        isShowingAdminButton.value = false;
      }
    });
}

const setStatusCancel = async () => {

  xhr.addRequestToQueue("PUT", `/produits/${props.produit.uuid}/cancel`, undefined, true, true, false,
    (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("productStatusUpdated"),
            "success");

        const newProduit = {...(props.produit)};
        newProduit.etat.libelle = enumeration.etatObjet.ANNULE;
        commandeStore.updateProduitInCommandeSelected(newProduit);
        isShowingAdminButton.value = false;
      }
    });
}

const updateProduit = () => {
  if(!editQuantity.value || editQuantity.value <= 0 || !editName.value || editName.value.trim() === "") {
    notificationStore.addNotification(
        t("needFillAllFields"),
        "error");
    return;
  }

  const data = {
    nom: editName.value,
    uuidCouleur: editColor.value,
    nombre: editQuantity.value,
    remplissage: editFilling.value,
    stl: editValueStl.value.content
  }

  xhr.addRequestToQueue("PUT", "/produits/" + props.produit.uuid, data, true, true, false,
    (response) => {
      if(response.code !== 200) {
        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("productEditedSuccessfully"),
            "success");

        isEditMode.value = false;

        const selectedCommande = commandeStore.commandeSelected;

        const produit = selectedCommande.produits.find(p => p.uuid === props.produit.uuid);

        produit.nom = editName.value;
        produit.couleur = couleurStore.couleurs.find(c => c.uuid === editColor.value);
        produit.nombre = editQuantity.value;
        produit.remplissage = editFilling.value;

        editValueStl.value.name = editName.value;

        if(editValueStl.value.content) {
          produit.fichier = editValueStl.value;
        }
      }
  })
}

const setToPrinted = async () => {

  let toutEstSaisieSurLesBobines;
  let nbObjetFull = 0;

  if(selectedBobines.full.length > 0) {
    nbObjetFull = selectedBobines.full.reduce((acc, bobine) => acc + bobine.nbObjet, 0);
  }

  if(nbObjetFull === props.produit.nombre) {
    toutEstSaisieSurLesBobines = true;

  } else {
    let poidObjetPartiel = 0;
    if(selectedBobines.partial.length > 0) {
      poidObjetPartiel = selectedBobines.partial.reduce((acc, bobine) => acc + bobine.poids, 0);
    }

    const poidObjetsPartielNeccesaire = (props.produit.nombre - nbObjetFull) * poidEdit.value;
    toutEstSaisieSurLesBobines = poidObjetPartiel === poidObjetsPartielNeccesaire;
  }


  if(!poidEdit.value || poidEdit.value <= 0 || !tempsEdit.value || poidEdit.value <= 0 || !toutEstSaisieSurLesBobines || printerUsed.value === -1) {
    notificationStore.addNotification(
        t("needFillAllFields"),
        "error");
    return;
  }

  let isGoodForTemp;
  try {
    const evaluatedValue = parser().evaluate(tempsEdit.value.toString());
    isGoodForTemp = !isNaN(evaluatedValue);
  } catch(e) {
    isGoodForTemp = false;
  }
  if(!isGoodForTemp) {
    notificationStore.addNotification(
        t("weightNeedBeNumber"),
        "error");
    return;
  }


  const formatedBobine = {
    full: selectedBobines.full.map(bobine => ({ uuid: bobine.bobine.uuid, nbObjet: bobine.nbObjet })),
    partial: selectedBobines.partial.map(bobine => ({ uuid: bobine.bobine.uuid, poids: bobine.poids }))
  };

  xhr.addRequestToQueue("PUT", `/produits/${props.produit.uuid}/printed`,
    {poid: poidEdit.value, temp: tempsEdit.value, bobines: formatedBobine, uuidImprimante: printerUsed.value}, true, true, false,
    (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("weightAndTimeUpdated"),
            "success");

        const newProduit = {...(props.produit)};
        newProduit.poid = poidEdit.value;
        newProduit.temp = tempsEdit.value;
        newProduit.etat.libelle = enumeration.etatObjet.IMPRIMEE;
        newProduit.imprimante = imprimanteStore.imprimantes.find(imprimante => imprimante.uuid === printerUsed.value);
        commandeStore.updateProduitInCommandeSelected(newProduit);
        isShowingAdminButton.value = false

        selectedBobines.full.forEach(bobine => {
          const bobineSelected = bobineStore.bobines.find(b => b.uuid === bobine.bobine.uuid);
          bobineSelected.poidsRestant -= (poidEdit.value * bobine.nbObjet);

          if(bobineSelected.poidsRestant <= 0) {
            bobineStore.bobines = bobineStore.bobines.filter(b => b.uuid !== bobine.bobine.uuid);
          } else {
            bobineStore.bobines = bobineStore.bobines.map(b => {
              if(b.uuid === bobine.bobine.uuid) {
                return bobineSelected;
              }
              return b;
            });
          }
        });
      }

      selectedBobines.partial.forEach(bobine => {
        const bobineSelected = bobineStore.bobines.find(b => b.uuid === bobine.bobine.uuid);
        bobineSelected.poidsRestant -= bobine.poids;

        if(bobineSelected.poidsRestant <= 0) {
          bobineStore.bobines = bobineStore.bobines.filter(b => b.uuid !== bobine.bobine.uuid);
        } else {
          bobineStore.bobines = bobineStore.bobines.map(b => {
            if(b.uuid === bobine.bobine.uuid) {
              return bobineSelected;
            }
            return b;
          });
        }
      });
    });
}

const resolveMathTemps = () => {
  try {
    tempsEdit.value = parser().evaluate(tempsEdit.value);
  } catch(e) {
    console.log("not good syntax");
  }
}

const closeModalSelectedBobine = (bobineSelected) => {
  if(bobineSelected) {
    selectedBobines.full = bobineSelected.full;
    selectedBobines.partial = bobineSelected.partial;
  }

  showModalBobine.value = false;
}
</script>

<style scoped>
</style>