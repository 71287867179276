<template>
  <BaseModal :isVisible="isVisible">
    <Render3D ref="render" :hideModal="hideModal" :isVisible="isVisible" :uuidProduit="uuidProduit" :base64Data="base64Data" />

    <BlackClickableText @click="closeModal" :text="t('closeAction')" class="w-full my-2" />
  </BaseModal>
</template>

<script setup>

import {defineProps, useTemplateRef} from 'vue';
import {t} from '@/utils/Traduction';
import BlackClickableText from "@/components/molecules/BlackClickableText.vue";
import BaseModal from "@/components/molecules/BaseModal.vue";
import Render3D from "@/components/molecules/Render3D.vue";

defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  uuidProduit: {
    type: String,
    required: false,
  },
  base64Data: {
    type: String,
    required: false,
  },
  hideModal: {
    type: Function,
    required: true,
  },
});

const render = useTemplateRef("render");

const closeModal = () => {
  render.value.closeModal();
}

</script>

<style scoped>
</style>
