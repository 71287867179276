<template>
  <BaseIdCard innerClass="p-6">
    <div class="absolute right-6 top-4">
      <EuroIcon v-show="showEuroIcon" />
      <EuroBarrerIcon v-show="showCrossedEuroIcon" />

      <InputField type="checkbox" v-show="isMultiOrderInvoceMode" @change="() => toogleOrderToMultiOrderInvoice(props.commande.uuid)"  placeholder=""/>
    </div>

    <div class="flex justify-between items-center">
      <div class="flex flex-col gap-1">
        <TitreDeux :content="commande.nom" />
        <EtatChips :etat="props.commande.etat" />

        <BaseKeyValue :cles="t('orderBy')" v-if="isAdmin" size="text-sm">
          <SimpleText :content="props.commande.utilisateur.email" size="text-sm" />
        </BaseKeyValue>

        <BaseKeyValue :cles="t('delivryAddress')" v-if="isAdmin" color="text-gray-700" size="text-sm">
          <SimpleText :content="props.commande.utilisateur.livraison" color="text-gray-700" size="text-sm" />
        </BaseKeyValue>

        <BaseKeyValue :cles="t('orderDate')" color="text-gray-600" size="text-sm">
          <SimpleText :content="dateFormatee" color="text-gray-600" size="text-sm" />
        </BaseKeyValue>
      </div>

      <BlueRightArrowIcon @click="router.push('/commandes/' + props.commande.uuid)" />
    </div>
  </BaseIdCard>
</template>

<script setup>

import {computed, defineProps} from 'vue';
import EtatChips from "@/components/molecules/EtatChips.vue";
import {t} from "@/utils/Traduction";
import EuroIcon from "@/components/atoms/EuroIcon.vue";
import EuroBarrerIcon from "@/components/atoms/EuroBarrerIcon.vue";
import InputField from "@/components/molecules/InputField.vue";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import BlueRightArrowIcon from "@/components/atoms/BlueRightArrowIcon.vue";
import {useRouter} from "vue-router";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../../Enumeration";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";

const props = defineProps({
  commande: Object,
  isMultiOrderInvoceMode: Boolean,
  toogleOrderToMultiOrderInvoice: Function
});

const utilisateurStores = UtilisateurStores();
const isAdmin = computed(() => utilisateurStores.role === enumeration.role.ADMIN);
const showEuroIcon = computed(() => !props.commande.estGratuite && !props.isMultiOrderInvoceMode);
const showCrossedEuroIcon = computed(() => props.commande.estGratuite && !props.isMultiOrderInvoceMode);

const router = useRouter();

let dateFormatee = '';
if(props.commande.dateDebut) {
  const dateObj = new Date(props.commande.dateDebut);

  const jour = dateObj.getUTCDate().toString().padStart(2, '0');
  const mois = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  const annee = dateObj.getUTCFullYear();

  dateFormatee = `${jour}-${mois}-${annee}`;
}


</script>

<style scoped>

</style>