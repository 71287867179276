<template>
  <nav class="bg-primary p-4 flex items-center justify-between">
    <div class="text-white text-xl font-bold">Market I3D</div>
    <div class="md:hidden">
      <button @click="toggleMenu" class="text-white hover:text-hover-white">
        <font-awesome-icon icon="fas fa-bars" class="text-lg"/>
      </button>
    </div>

    <div class="hidden md:flex space-x-4 items-center">
      <NavMenuDeroulant :title="t('administration')" v-if="isAdmin"
        :childrens="[
          {text: t('coils'), redirect: '/admin/bobines'},
          {text: t('informations'), redirect: '/admin/informations'},
          {text: t('colors'), redirect: '/admin/couleurs'},
          {text: t('printers'), redirect: '/admin/imprimantes'},
          {text: t('brands'), redirect: '/admin/marques'},
          {text: t('filamentsType'), redirect: '/admin/typefilaments'},
          {text: t('messages'), redirect: '/admin/contact'},
          {text: t('register'), redirect: '/admin/inscription'}
        ]"/>

      <WhiteNavRouterLink v-if="isConnected" to="/commandes/create" :text="t('createAnOrder')" />
      <WhiteNavRouterLink v-if="isClient" to="/commandes" :text="t('myOrders')" />
      <WhiteNavRouterLink v-if="isAdmin" to="/admin/commandes" :text="t('orders')" />
      <WhiteNavRouterLink v-if="isConnected" to="/model" :text="t('show3D')" />
      <WhiteNavRouterLink to="/contact" :text="t('contact')" />
    </div>

    <div class="hidden md:flex flex-row gap-2">
      <WhiteBookIcon v-if="isConnected" @click="$router.push('/versions')" />
      <WhiteUserIcon v-if="isConnected" @click="$router.push('/me')" />
      <WhiteClickableText :text="t('logout')" v-if="isConnected" @click="logout" class="hover:text-hover-white" />
      <WhiteNavRouterLink v-else :text="t('login')" to="/" />
    </div>
  </nav>

  <!-- Menu déroulant animé depuis la droite (inchangé) -->
  <Transition name="slide-from-right" class="z-50">
    <div v-if="isMenuOpen" class="md:hidden bg-blue-500 fixed top-0 right-0 h-full w-60 flex flex-col text-white text-xl overflow-y-scroll">
      <button @click="toggleMenu" class="text-white hover:text-hover-white ml-auto p-4">
        <font-awesome-icon icon="fas fa-times" class="text-lg"/>
      </button>

      <WhiteNavRouterLink v-if="isAdmin" :text="t('filamentsType')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/typefilaments" />
      <WhiteNavRouterLink v-if="isAdmin" :text="t('brands')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/marques" />
      <WhiteNavRouterLink v-if="isAdmin" :text="t('informations')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/informations" />
      <WhiteNavRouterLink v-if="isAdmin" :text="t('printers')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/imprimantes" />
      <WhiteNavRouterLink v-if="isAdmin" :text="t('colors')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/couleurs" />
      <WhiteNavRouterLink v-if="isAdmin" :text="t('coils')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/bobines" />
      <WhiteNavRouterLink v-if="isConnected" :text="t('createAnOrder')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/commandes/create" />
      <WhiteNavRouterLink v-if="isClient" :text="t('myOrders')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/commandes" />
      <WhiteNavRouterLink v-if="isAdmin" :text="t('orders')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/commandes" />
      <WhiteNavRouterLink v-if="isConnected" :text="t('show3D')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/model" />

      <WhiteNavRouterLink v-if="isAdmin" :text="t('register')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/inscription" />
      <WhiteNavRouterLink v-if="isAdmin" :text="t('messages')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/admin/contact" />
      <WhiteNavRouterLink :text="t('contact')" class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/contact" />
      <WhiteNavRouterLink :text="t('versionsOnly')" class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/versions" />
      <WhiteNavRouterLink v-if="isConnected" :text="t('myAccount')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/me" />
      <WhiteClickableText :text="t('logout')" v-if="isConnected" @click="logout"
          class="hover:text-hover-white" />
      <WhiteNavRouterLink v-else :text="t('login')"
          class="hover:bg-blue-600 p-4" @click="toggleMenu" to="/" />
    </div>
  </transition>
</template>


<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {router} from "@/routeur";
import {enumeration} from '../../../Enumeration';
import {computed, ref} from "vue";
import {t} from "@/utils/Traduction";
import NavMenuDeroulant from "@/components/molecules/NavMenuDeroulant.vue";
import WhiteNavRouterLink from "@/components/molecules/WhiteNavRouterLink.vue";
import WhiteBookIcon from "@/components/atoms/WhiteBookIcon.vue";
import WhiteUserIcon from "@/components/atoms/WhiteUserIcon.vue";
import WhiteClickableText from "@/components/molecules/WhiteClickableText.vue";
import {createRouter as $router} from "vue-router";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const isMenuOpen = ref(false);

const isConnected = computed(() => utilisateurStore.isConnected);
const isAdmin = computed(() => utilisateurStore.role === enumeration.role.ADMIN);
const isClient = computed(() => utilisateurStore.role === enumeration.role.CLIENT);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
}

const logout = async () => {
  utilisateurStore.logout(false);

  notificationStore.addNotification(
      t("youAreDisconnected"),
      "success");

  toggleMenu();

  await router.push("/");
}

</script>

<style scoped>
.slide-from-right-enter-active {
  animation: slide-from-right-enter 0.5s;
}

.slide-from-right-leave-active {
  animation: slide-from-right-leave 0.5s;
}

@keyframes slide-from-right-enter {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-from-right-leave {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

</style>