import {createRouter, createWebHistory} from 'vue-router'
import ConnexionPage from './pages/ConnexionPage.vue'
import NotFoundView from './pages/NotFoundPage.vue'
import ContactPage from './pages/ContactPage.vue'
import AdminContactPage from './pages/AdminContactPage.vue'
import AdminInscriptionPage from "@/pages/AdminInscriptionPage";
import AdminCommandePage from "@/pages/AdminCommandePage";
import CommandePage from "@/pages/CommandePage";
import DetailCommandePage from "@/pages/DetailCommandePage";
import CreationCommandePage from "@/pages/CreationCommandePage";
import AccountPage from "@/pages/AccountPage";
import AdminBobinesPage from "@/pages/AdminBobinesPage";
import VersionPage from "@/pages/VersionPage.vue";
import StatistiquePage from "@/pages/StatistiquePage.vue";
import AdminCouleursPage from "@/pages/AdminCouleursPage.vue";
import {t, Traduction} from "@/utils/Traduction";
import AdminImprimantePage from "@/pages/AdminImprimantePage.vue";
import AdminInformationPage from "@/pages/AdminInformationPage.vue";
import StatistiqueImprimantePage from "@/pages/StatistiqueImprimantePage.vue";
import AdminMarquePage from "@/pages/AdminMarquePage.vue";
import AdminTypeFilamentPage from "@/pages/AdminTypeFilamentPage.vue";
import Show3DModelPage from "@/pages/Show3DModelPage.vue";


export const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: "Connexion", component: ConnexionPage, meta: { name: t("connection") ? t("connection") : Traduction.en.connection }},
        { path: '/contact', name: "Contact", component: ContactPage, meta: { name: t("contact") ? t("contact") : Traduction.en.contact}},
        { path: '/admin/contact', name: "Les messages", component: AdminContactPage, meta: { name: t("messages") }},
        { path: '/admin/bobines', name: "Les bobines", component: AdminBobinesPage, meta: { name: t("coils") }},
        { path: '/admin/inscription', name: "Inscription", component: AdminInscriptionPage, meta: { name: t("register") }},
        { path: '/admin/commandes', name: "Les commandes", component: AdminCommandePage, meta: { name: t("orders") }},
        { path: '/commandes', name: "Mes commandes", component: CommandePage, meta: { name: t("myOrders") }},
        { path: '/commandes/create', name: "Creation commande",  component: CreationCommandePage, meta: { name: t("createOrder") }},
        { path: '/commandes/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})',
            name: "DetailCommande", props: true, component: DetailCommandePage, meta: { name: t("orderDetail") }},
        { path: '/me', name: "Mon compte", component: AccountPage, meta: { name: t("myAccount") }},
        { path: '/versions', name: "Les versions", component: VersionPage, meta: { name: t("versions") }},
        { path: '/stats/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})',
            name: "Stats", props: true, component: StatistiquePage, meta: { name: t("stats") }},
        { path: '/admin/couleurs', name: "Les couleur", component: AdminCouleursPage, meta: { name: t("colors") }},
        { path: '/admin/imprimantes', name: "Les imprimantes", component: AdminImprimantePage, meta: { name: t("printers") }},
        { path: '/admin/imprimantes/stats/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})',
            name: "Stats imprimante", props: true, component: StatistiqueImprimantePage, meta: { name: t("statsPrinter") }},
        { path: '/admin/informations', name: "Les informations", component: AdminInformationPage, meta: { name: t("informations") }},
        { path: '/admin/marques', name: "Les marques", component: AdminMarquePage, meta: { name: t("brands") }},
        { path: '/admin/typefilaments', name: "Les type de filaments", component: AdminTypeFilamentPage, meta: { name: t("filamentsType") }},
        { path: '/model', name: "Afficher model 3D", component: Show3DModelPage, meta: { name: t("show3D") }},
        {
            path: '/:patchMatch(.*)*',
            name: "notFound",
            component: NotFoundView,
            meta: { name: t("pageNotFoundShort") ? t("pageNotFoundShort") : Traduction.en.pageNotFoundShort }
        }
    ]
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.name

    let metaAuthor = document.querySelector('meta[name="author"]');
    if (!metaAuthor) {
        metaAuthor = document.createElement('meta');
        metaAuthor.setAttribute('name', 'author');
        document.getElementsByTagName('head')[0].appendChild(metaAuthor);
    }
    metaAuthor.setAttribute('content', 'Killian Gouzien, NuRoZ');

    let metaDesription = document.querySelector('meta[name="description"]');
    if (!metaDesription) {
        metaDesription = document.createElement('meta');
        metaDesription.setAttribute('name', 'description');
        document.getElementsByTagName('head')[0].appendChild(metaDesription);
    }
    metaDesription.setAttribute('description', 'Bonjour, je suis killian gouzien, je suis ' +
        'passionner d\'impression 3D depuis 2022. Ces pour cela que j\'ai créer un site sous le nom de "marketi3"' +
        ' qui vous propose un service qui vous permet d\'imprimer des objets en 3D. Pour cela contactez moi ' +
        'pour plus d\'informations ou pour vous créer un compte.');

    if(from.name === "Connexion" && localStorage.getItem("redirectAfterLogin")) {
        localStorage.removeItem("redirectAfterLogin");
    } else if(to.name === "Connexion" && localStorage.getItem("keepRedirect") === "true") {
        localStorage.setItem("redirectAfterLogin", from.path);
        localStorage.removeItem("keepRedirect");
    }

    next();
});
