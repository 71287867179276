<template>
  <div class="p-3 bg-gray-100 rounded border">
    <SimpleText :content="props.content" :dangerouslySetInnerHTML="dangerouslySetInnerHTML" :textCenter="textCenter"
      :color="color" :bolding="bolding" />
  </div>
</template>

<script setup>

import SimpleText from "@/components/atoms/SimpleText.vue";
import {defineProps} from 'vue';

const props = defineProps({
  content: {
    type: String,
    required: true
  },
  dangerouslySetInnerHTML: {
    type: Boolean,
    default: false
  },
  textCenter: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    required: false,
    default: "text-black"
  },
  bolding: {
    type: String,
    default: "font-normal"
  }
})

</script>

<style scoped>

</style>