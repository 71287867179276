<template>
  <div class="flex flex-col w-4/5 ml-[10%] gap-4 mt-8 lg:mt-16">
    <PurpleFileInputField v-model="file" v-on:change="showAgain" />
    <Render3D :hideModal="closeModal" :isVisible="show3DModel" :base64Data="fileContent" />
  </div>
</template>

<script setup>

import PurpleFileInputField from "@/components/molecules/PurpleFileInputField.vue";
import {computed, ref} from "vue";
import Render3D from "@/components/molecules/Render3D.vue";

const file = ref({});
const wantShow3Drender = ref(true);

const fileContent = computed(() => file.value.content);
const show3DModel = computed(() => wantShow3Drender.value && !!fileContent.value);

const closeModal = () => {
  wantShow3Drender.value = false;
}

</script>

<style scoped>

</style>