<template>
  <BaseModal :isVisible="isVisible">
    <div v-show="step === 1" class="w-full flex flex-col items-center">
      <SimpleText :content="t('numberObjetAvailable') + ' : ' + (props.nbProduit - totalNbObjetTotalAttribuer)" />

      <div class="flex flex-col md:flex-row md:flex-wrap gap-2 md:gap-4 justify-center items-center mb-4 w-full overflow-y-auto">
        <BaseKeyValue v-for="bobine of bobinesComplete" :key="bobine.bobine.uuid"
              :cles="bobine.bobine.nombre + ' - ' + bobine.bobine.couleur.libelle + ' - ' + bobine.bobine.poidsRestant">
          <CounterButton :value="bobine.nbObjet" @addOne="addOne(bobine.bobine.uuid)" @removeOne="removeOne(bobine.bobine.uuid)" />
        </BaseKeyValue>
      </div>
    </div>

    <div v-show="step === 2" class="w-full flex flex-col items-center">
      <SimpleText :content="t('remainingWeight') + ' : ' + ((props.poidProduit * (props.nbProduit - totalNbObjetTotalAttribuer)) - totalPoidPartielAttribuer)"
      :color="(props.poidProduit * (props.nbProduit - totalNbObjetTotalAttribuer)) - totalPoidPartielAttribuer < 0 ? 'text-red-500' : ''"/>

      <div class="flex flex-col md:flex-row flex-wrap gap-4 justify-center items-center mb-4 w-full overflow-y-auto">

        <BaseKeyValue v-for="bobine of bobinesPartiel" :key="bobine.bobine.uuid" class="w-[95%] md:w-[45%]"
            :cles="bobine.bobine.nombre + ' - ' + bobine.bobine.couleur.libelle + ' - ' + bobine.bobine.poidsRestant">
          <InputField :placeholder="t('weights')" type="number" :min="0" v-model="bobine.poids"/>
        </BaseKeyValue>
      </div>
    </div>

    <div class="flex flex-row justify-center w-full gap-4 mt-8">
      <BlueButton v-show="step !== 1" @click="goToPreviousStep" :text="t('previous') + ' ←'" />
      <BlueButton @click="cancel" :text="t('cancel2')" />
      <BlueButton data-testid="validateChoixPourBobine" v-show="peutValider" @click="validate" :text="t('validate')" />
      <BlueButton v-show="props.nbProduit - totalNbObjetTotalAttribuer !== 0 && step !== 2" @click="step++" :text="t('next') + ' →'" />
    </div>
  </BaseModal>
</template>

<script setup>
import {computed, defineExpose, defineProps, reactive, ref} from 'vue';
import {t} from "@/utils/Traduction";
import SimpleText from "@/components/atoms/SimpleText.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import BaseModal from "@/components/molecules/BaseModal.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import InputField from "@/components/molecules/InputField.vue";
import CounterButton from "@/components/molecules/CounterButton.vue";

const props = defineProps({
  nbProduit: {
    type: Number,
    required: true
  },
  poidProduit: {
    type: Number,
    required: true
  },
  bobinesDeLaCouleur: {
    type: Array,
    required: true
  },
  closeModal: {
    type: Function,
    required: true
  },
  isVisible: {
    type: Boolean,
    required: true
  }
});

const step = ref(1);

const bobinesComplete = reactive(
    props.bobinesDeLaCouleur.map(bobine => ({ bobine, nbObjet: 0 }))
);

const bobinesPartiel = reactive(
    props.bobinesDeLaCouleur.map(bobine => ({ bobine, poids: 0 }))
);

const totalNbObjetTotalAttribuer = computed(() => {
  return bobinesComplete.reduce((total, bobine) => total + bobine.nbObjet, 0);
});

const totalPoidPartielAttribuer = computed(() => {
  return bobinesPartiel.reduce((total, bobine) => total + bobine.poids, 0);
});

const peutValider = computed(() => {
  return totalNbObjetTotalAttribuer.value === props.nbProduit ||
      totalPoidPartielAttribuer.value == props.poidProduit * (props.nbProduit - totalNbObjetTotalAttribuer.value);
});

const reset = () => {
  bobinesComplete.forEach(bobine => bobine.nbObjet = 0);
  bobinesPartiel.forEach(bobine => bobine.poids = 0);
}

defineExpose({ reset });

const addOne = (uuid) => {
  const bobine = bobinesComplete.find(bobine => bobine.bobine.uuid === uuid);

  // on utilise != car un est string et l'autre est nombre
  if(bobine && totalNbObjetTotalAttribuer.value != props.nbProduit) {
    bobine.nbObjet += 1;
  }
}

const removeOne = (uuid) => {
  const bobine = bobinesComplete.find(bobine => bobine.bobine.uuid === uuid);

  if(bobine && bobine.nbObjet > 0) {
    bobine.nbObjet -= 1;
  }
}

const cancel = () => {
  props.closeModal();
}

const validate = () => {
  const full = bobinesComplete.filter(bobine => bobine.nbObjet > 0);
  const partial = bobinesPartiel.filter(bobine => bobine.poids > 0);
  const selected = {full, partial}

  props.closeModal(selected);
}

const goToPreviousStep = () => {

  if(step.value === 2) {
    bobinesPartiel.forEach(bobine => bobine.poids = 0);
  }

  step.value--;
}

</script>

<style scoped>
</style>